import { BugsnagLogger } from "@clio/frontend-logging";
import { CLIO_FOR_CLIENTS_FE_API_KEY } from "./api-keys";

try {
  const appState = (<any>window).gonData;

  const logger = BugsnagLogger.start({
    apiKey: CLIO_FOR_CLIENTS_FE_API_KEY,
    enabledReleaseStages: [
      "production",
      "production-ca",
      "production-eu",
      "staging",
      "beta",
    ],
    releaseStage: appState.environment,
  });

  (window as any).clioLogger = logger;
} catch (err) {
  console.warn("Error in Bugsnag bootstrap");
  const noopFn = () => {
    return;
  };
  (window as any).clioLogger = { info: noopFn, warning: noopFn, error: noopFn };
}
