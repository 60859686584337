import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["calendarEntryDetailsModal", "zoom"];

  calendarEntryDetailsModalTarget?: Element;
  hasZoomTarget?: boolean;
  zoomTarget?: Element;

  toggle(event: PointerEvent) {
    if (this.hasZoomTarget && event.target === this.zoomTarget) {
      return;
    }

    this.calendarEntryDetailsModalTarget?.classList.toggle("hidden");
  }
}
