import { Controller } from "stimulus";

export default class extends Controller {
  connect() {
    const element = this.element as HTMLElement;
    const userId = element?.dataset?.identifyUserId;
    if (userId) {
      const appState = (<any>window).gonData;
      (<any>window).analytics.identify(appState.idPrefix + userId);
    }
  }
}
