import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["unreadIndicator"];

  unreadIndicatorTarget?: Element;

  removeUnreadIndicator() {
    this.unreadIndicatorTarget?.classList.add("hidden");
  }
}
