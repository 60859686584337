import { Controller } from "stimulus";

export default class extends Controller {
  static targets = [
    "case",
    "menu",
    "menuButton",
    "menuCloseIcon",
    "menuOpenIcon",
    "menuUnreadIndicator",
    "helpMenu",
    "languageMenu",
  ];
  caseTarget?: Element;
  menuTarget?: Element;
  menuButtonTarget?: Element;
  menuCloseIconTarget?: Element;
  menuOpenIconTarget?: Element;
  menuUnreadIndicatorTarget?: Element;
  helpMenuTarget?: Element;
  languageMenuTarget?: Element;
  hasMenuUnreadIndicatorTarget!: boolean;

  private setMenuIconOpen(open: boolean) {
    if (open) {
      this.menuButtonTarget?.classList.add("text-blue-link-500");
      this.menuButtonTarget?.classList.remove("text-grey-500");
      this.menuOpenIconTarget?.classList.add("hidden");
      this.menuCloseIconTarget?.classList.remove("hidden");
      if (this.hasMenuUnreadIndicatorTarget) {
        this.menuUnreadIndicatorTarget?.classList.add("hidden");
      }
    } else {
      this.menuButtonTarget?.classList.remove("text-blue-link-500");
      this.menuButtonTarget?.classList.add("text-grey-500");
      this.menuOpenIconTarget?.classList.remove("hidden");
      this.menuCloseIconTarget?.classList.add("hidden");
      if (this.hasMenuUnreadIndicatorTarget) {
        this.menuUnreadIndicatorTarget?.classList.remove("hidden");
      }
    }
  }

  private setMenuVisible(visible: boolean) {
    if (visible) {
      this.menuTarget?.classList.remove("hidden");
    } else {
      this.menuTarget?.classList.add("hidden");
    }
  }

  private setHelpMenuVisible(visible: boolean) {
    if (visible) {
      this.helpMenuTarget?.classList.remove("hidden");
    } else {
      this.helpMenuTarget?.classList.add("hidden");
    }
  }

  private setLanguageMenuVisible(visible: boolean) {
    if (visible) {
      this.languageMenuTarget?.classList.remove("hidden");
    } else {
      this.languageMenuTarget?.classList.add("hidden");
    }
  }

  toggleCase() {
    this.setMenuIconOpen(false);
    this.setMenuVisible(false);
    this.caseTarget?.classList.toggle("hidden");
  }

  openMenu() {
    this.setMenuIconOpen(true);
    this.setMenuVisible(true);
  }

  closeMenu() {
    this.setMenuIconOpen(false);
    this.setMenuVisible(false);
    this.setHelpMenuVisible(false);
  }

  openHelpMenu() {
    this.setHelpMenuVisible(true);
    this.setHelpMenuVisible(true);
  }

  changeLanguage() {
    this.closeMenu();
    this.setLanguageMenuVisible(true);
  }

  closeHelpMenu() {
    this.setHelpMenuVisible(false);
  }

  closeLanguageMenu() {
    this.setLanguageMenuVisible(false);
  }

  backFromLanguageMenu() {
    this.setLanguageMenuVisible(false);
    this.openMenu();
  }
}
