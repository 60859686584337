import { Controller } from "stimulus";

export default class extends Controller {
  static targets = [
    "unreadCount",
    "documentUnreadIndicator",
    "globalUnreadIndicator",
  ];

  unreadCountTarget?: HTMLElement;
  globalUnreadIndicatorTarget?: HTMLElement;
  documentUnreadIndicatorTargets?: Array<Element>;

  canHideGlobalUnreadIndicator = false;

  connect() {
    this.canHideGlobalUnreadIndicator =
      this.unreadCount() === this.unreadIndicatorCount();
  }

  removeUnreadIndicator() {
    if (
      this.canHideGlobalUnreadIndicator &&
      this.unreadIndicatorCount() === 0
    ) {
      this.globalUnreadIndicatorTarget?.classList.add("hidden");
    }
  }

  private unreadCount() {
    return parseInt(this.unreadCountTarget?.innerHTML ?? "0", 10);
  }

  private unreadIndicatorCount() {
    return (
      this.documentUnreadIndicatorTargets?.filter(
        (element) => !element.classList.contains("hidden")
      ).length ?? 0
    );
  }
}
