import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["scrollView", "unreadSeparator", "moreButton"];

  scrollViewTarget?: HTMLElement;
  unreadSeparatorTarget?: HTMLElement;
  moreButtonTarget?: HTMLElement;
  hasUnreadSeparatorTarget?: boolean;

  connect() {
    const observer = new IntersectionObserver(
      (entries) => {
        if (entries[0].isIntersecting) {
          this.moreButtonTarget?.classList.add("invisible");
          observer.disconnect();
        }
      },
      { root: this.scrollViewTarget }
    );

    try {
      if (this.unreadSeparatorTarget) {
        observer.observe(this.unreadSeparatorTarget);
      }
    } catch (e) {
      // Do nothing
    }
  }

  scrollToUnreadSeparator() {
    const target = this.hasUnreadSeparatorTarget
      ? this.unreadSeparatorTarget
      : document.querySelector(".portal-entries");
    target?.scrollIntoView({ behavior: "smooth" });
  }
}
