import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["menu"];
  declare readonly menuTarget: Element;

  toggleLanguageMenu(): void {
    this.menuTarget.classList.toggle("hidden");
  }
}
