import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["info", "infoButton", "backdrop"];
  backdropTarget?: Element;
  infoTarget?: Element;
  infoButtonTarget?: Element;

  private setInfoVisible(visible: boolean) {
    this.infoButtonTarget?.setAttribute("aria-expanded", String(visible));
    this.infoButtonTarget?.children[0].classList.toggle(
      "text-blue-link-500",
      visible
    );
    this.infoButtonTarget?.children[0].classList.toggle(
      "text-grey-500",
      !visible
    );
    this.infoTarget?.classList.toggle("hidden", !visible);
    this.backdropTarget?.classList.toggle("hidden", !visible);
  }

  openInfo() {
    this.setInfoVisible(true);
  }

  closeOnEsc(e: KeyboardEvent) {
    if (e.code == "Escape") {
      this.closeInfo(e);
    }
  }

  closeInfo(e: Event) {
    e.stopPropagation();
    this.setInfoVisible(false);
  }
}
