// Load all the controllers within this directory and all subdirectories.
// Controller files must be named *_controller.js.

import { Application } from "stimulus";
import { definitionsFromContext } from "stimulus/webpack-helpers";

const timeZoneString = Intl.DateTimeFormat().resolvedOptions().timeZone;

document.cookie = "time_zone_string=" + timeZoneString + "; path=/";

const application = Application.start();
const context = require.context("controllers", true, /\.ts$/);
application.load(definitionsFromContext(context));
