import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["welcome", "button", "language", "languageButton"];

  welcomeTarget?: HTMLElement;
  languageTarget?: HTMLElement;
  buttonTarget?: HTMLElement;
  languageButtonTarget?: HTMLElement;
  closedWelcomeKey?: string;

  connect() {
    // check if the user is logged in for the first time or just refreshing
    this.closedWelcomeKey =
      "closedWelcome" + this.welcomeTarget?.dataset?.clientid;
    if (localStorage.getItem(this.closedWelcomeKey) === "true") {
      this.languageTarget?.classList.add("hidden");
    } else {
      this.languageButtonTarget?.focus();
    }
  }

  closeWelcomeModal() {
    this.welcomeTarget?.classList.add("hidden");
    this.buttonTarget?.blur();
    if (this.closedWelcomeKey) {
      localStorage.setItem(this.closedWelcomeKey, "true");
    }
  }

  closeLanguageModal() {
    this.languageTarget?.classList.add("hidden");
    this.languageButtonTarget?.blur();
    this.welcomeTarget?.classList.remove("hidden");
    this.welcomeTarget?.classList.add("visible");
    this.buttonTarget?.focus();
  }
}
