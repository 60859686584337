import { Controller } from "stimulus";

export default class extends Controller {
  remove() {
    this.element.hasAttribute("data-error-hidden")
      ? this.element.classList.add("hidden")
      : this.element.remove();
  }

  dismiss() {
    this.element.classList.add("hidden");
  }
}
