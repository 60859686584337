import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["content", "moreButton", "lessButton"];
  static values = { lines: Number };

  contentTarget?: HTMLElement;
  moreButtonTarget?: HTMLElement;
  lessButtonTarget?: HTMLElement;
  linesValue?: number;
  DEFAULT_NUMBER_OF_LINES = 3;

  connect() {
    this.render();
  }

  render() {
    this.showAllContent();

    if (this.height() > this.maxHeightBeforeTruncation()) {
      this.showLess();
    } else {
      this.setMoreButtonVisibility(false);
      this.setLessButtonVisibility(false);
    }
  }

  showLess() {
    this.truncateContent();
    this.setMoreButtonVisibility(true);
    this.setLessButtonVisibility(false);
  }

  showMore() {
    this.showAllContent();
    this.setMoreButtonVisibility(false);
    this.setLessButtonVisibility(true);
  }

  private maxHeightBeforeTruncation() {
    return this.getNumberOfLinesToTruncate() * this.lineHeight();
  }

  private getNumberOfLinesToTruncate() {
    return this.linesValue ? this.linesValue : this.DEFAULT_NUMBER_OF_LINES;
  }

  private height() {
    return this.contentTarget ? this.contentTarget.offsetHeight : 0;
  }

  private lineHeight() {
    let parsedLineHeight = 0;

    if (this.contentTarget) {
      parsedLineHeight = parseFloat(
        window
          .getComputedStyle(this.contentTarget)
          .getPropertyValue("line-height")
      );
    }

    return parsedLineHeight;
  }

  private setLessButtonVisibility(visible: boolean) {
    this.lessButtonTarget?.classList.toggle("hidden", !visible);
  }

  private setMoreButtonVisibility(visible: boolean) {
    this.moreButtonTarget?.classList.toggle("hidden", !visible);
  }

  private showAllContent() {
    this.contentTarget?.classList.remove(
      `line-clamp-${this.getNumberOfLinesToTruncate()}`
    );
    this.contentTarget?.setAttribute("aria-hidden", "false");
  }

  private truncateContent() {
    this.contentTarget?.classList.add(
      `line-clamp-${this.getNumberOfLinesToTruncate()}`
    );
    this.contentTarget?.setAttribute("aria-hidden", "true");
  }
}
