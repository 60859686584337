import { DatadogLogger } from "@clio/frontend-logging";
import {
  CLIO_FOR_CLIENTS_FE_DD_APP_ID,
  CLIO_FOR_CLIENTS_FE_DD_CLIENT_TOKEN,
} from "./api-keys";

try {
  const appState = (<any>window).gonData;

  const validEnvironments = ["production", "production-eu", "staging"];

  const validRegions = ["us", "eu"];

  const isValidEnvironment = validEnvironments.includes(appState.environment);
  const isValidRegion = validRegions.includes(appState.region);

  if (isValidEnvironment && isValidRegion) {
    new DatadogLogger({
      applicationId: CLIO_FOR_CLIENTS_FE_DD_APP_ID,
      clientToken: CLIO_FOR_CLIENTS_FE_DD_CLIENT_TOKEN,
      env: appState.environment,
      region: appState.region,
      service: "clio-for-clients-web",
      version: "0.1.0",
    });
  }
} catch (err) {
  console.error(`Error in Datadog RUM bootstrap: ${(err as any)?.message}`);
}
