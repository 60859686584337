import { Controller } from "stimulus";

export default class extends Controller {
  trackEvent(event: Event) {
    const target = event.currentTarget as HTMLElement;
    const eventName = target?.dataset?.trackEventName;
    if (eventName) {
      const properties = this.getTrackingProperties(target);
      (<any>window).analytics.track(eventName, properties);
    }
  }

  private getTrackingProperties(elem: HTMLElement | null) {
    const properties = elem?.dataset?.trackProperties || "{}";
    return JSON.parse(properties);
  }
}
