import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["modal", "button"];

  modalTarget?: HTMLElement;
  buttonTarget?: HTMLElement;

  connect() {
    this.buttonTarget?.focus();
  }

  closeModal() {
    this.modalTarget?.classList.add("hidden");
    this.buttonTarget?.blur();
  }
}
