import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["resourceUnreadIndicator"];

  resourceUnreadIndicatorTarget?: Element;

  removeResourceUnreadIndicator() {
    this.resourceUnreadIndicatorTarget?.classList.add("hidden");
  }
}
