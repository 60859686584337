import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["submitButton"];
  submitButtonTarget?: Element;

  connect() {
    this.submitButtonTarget?.removeAttribute("disabled");
  }

  onClickSubmit() {
    this.submitButtonTarget?.setAttribute("disabled", "true");
  }
}
