import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["input"];
  textAreaMaxHeight = 40;
  private inputTarget?: HTMLInputElement;

  connect() {
    if (this.inputTarget) {
      this.inputTarget.style.resize = "none";
      this.inputTarget.style.minHeight = `${this.inputTarget.scrollHeight}px`;
      const maxRows = 4;
      this.textAreaMaxHeight =
        this.inputTarget.scrollHeight +
        parseInt(
          window
            .getComputedStyle(this.inputTarget)
            .getPropertyValue("line-height")
        ) *
          maxRows;
    }
  }

  resize(event: Event) {
    if (event && event.target) {
      const target = event.target as HTMLInputElement;
      target.style.height = "5px";
      target.style.height = `${Math.min(
        target.scrollHeight,
        this.textAreaMaxHeight
      )}px`;
    }
  }
}
