import { Controller } from "stimulus";

export default class extends Controller {
  static targets = [
    "loadMoreButton",
    "calendarEntriesList",
    "calendarEntriesCount",
  ];

  loadMoreButtonTarget?: HTMLElement;
  hasLoadMoreButtonTarget?: boolean;
  calendarEntriesListTarget?: HTMLElement;
  calendarEntriesCountTarget?: HTMLElement;
  nextPage?: string;

  initialize(): void {
    this.nextPage = this.hasLoadMoreButtonTarget
      ? this.loadMoreButtonTarget?.dataset.nextPage
      : undefined;
  }

  loadMore(): Promise<void> {
    return new Promise((resolve) => {
      if (!this.nextPage) {
        resolve();
        return;
      }

      fetch(this.nextPage, {
        method: "GET",
        headers: { Accept: "application/json" },
      })
        .then((response) => {
          return response.json();
        })
        .then((data) => {
          this.nextPage = data.next_page;
          this.calendarEntriesListTarget?.insertAdjacentHTML(
            "beforeend",
            data.entries
          );

          if (this.calendarEntriesCountTarget) {
            const currentCount = parseInt(
              this.calendarEntriesCountTarget.textContent || "0"
            );
            this.calendarEntriesCountTarget.textContent = `${
              data.total_records_on_page + currentCount
            }`;
          }

          if (!this.nextPage) {
            this.loadMoreButtonTarget?.classList.add("hidden");
          }
          resolve();
        });
    });
  }
}
